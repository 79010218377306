.App {
  text-align: center;
  overflow: hidden;
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  text-align: center;
  padding: 10px 0;
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.color-panel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.canvas-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.canvas {
  width: 80vw;
  height: 80vw;
  max-width: 60vh;
  max-height: 60vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  border-radius: 20px;
  box-shadow: 0 14px 14px rgba(0, 0, 0, 0.2);
}

.canvas-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.small-canvas {
  width: 80vw;
  height: 20%;
  max-width: 60vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.thumbnail {
  width: 30%;
  height: 100%;
  cursor: pointer;
  box-shadow: 0 14px 14px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  border-radius: 20px;
}

.thumbnail:hover {
  transform: translateY(-5px);
}

.thumbnail-image {
  width: 100%;
  height: 100%;
  object-fit:cover;
  border-radius: 20px;
}

.description-canvas {
  width: 80vw;
  height: 15%;
  max-width: 60vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  box-shadow: 0 14px 14px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  padding: 5px;
}

.description {
  font-size: 14px;
  color: #333;
  margin: 0;
}

.price {
  font-size: 18px;
  color: #000;
  font-weight: bold;
  margin: 5px 0 0 0;
}

button {
  position: absolute;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

.left-button {
  left: -5%;
  top: 50%;
  transform: translateY(-50%);
}

.top-button {
  top: -25%;
  left: 50%;
  transform: translateX(-50%);
}

.right-button {
  right: -5%;
  top: 50%;
  transform: translateY(-50%);
}

.bottom-button {
  bottom: -20%;
  left: 50%;
  transform: translateX(-50%);
}

.left-enter {
  transform: translateX(100%);
}

.left-enter-active {
  transform: translateX(0);
}

.left-exit {
  transform: translateX(0);
}

.left-exit-active {
  transform: translateX(-100%);
}

.right-enter {
  transform: translateX(-100%);
}

.right-enter-active {
  transform: translateX(0);
}

.right-exit {
  transform: translateX(0);
}

.right-exit-active {
  transform: translateX(100%);
}

.top-enter {
  transform: translateY(-100%);
}

.top-enter-active {
  transform: translateY(0);
}

.top-exit {
  transform: translateY(0);
}

.top-exit-active {
  transform: translateY(100%);
}

.bottom-enter {
  transform: translateY(100%);
}

.bottom-enter-active {
  transform: translateY(0);
}

.bottom-exit {
  transform: translateY(0);
}

.bottom-exit-active {
  transform: translateY(-100%);
}
